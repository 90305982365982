import { tryDeriveEndpointName } from 'vl-core/getApiEndpoint';

window.global ||= window;
const { REACT_APP_UI_VERSION, REACT_APP_AWS_ENDPOINT_NAME, REACT_APP_SETTINGS_URL, REACT_APP_BASEPATH, NODE_ENV } =
  import.meta.env || process.env;

// @ts-ignore
window.process ||= {
  env: {
    REACT_APP_UI_VERSION,
    REACT_APP_AWS_ENDPOINT_NAME: REACT_APP_AWS_ENDPOINT_NAME || tryDeriveEndpointName(),
    REACT_APP_SETTINGS_URL,
    REACT_APP_BASEPATH,
    NODE_ENV,
    PUBLIC_URL: REACT_APP_BASEPATH
  }
};
export {};
