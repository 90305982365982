import { useEffect } from 'react';
import { useAuth } from '@vl-core/useAuth';

function useUserInBothAppAndAdmin() {
  const auth = useAuth();

  // in vl-admin this will be true
  if (Array.isArray(auth)) {
    return auth[0];
  }

  return auth;
}

function maybeRedirectToBuildURL(build_url: URL) {
  const { host: targetHost, pathname: targetPathname } = build_url;
  const locn = new URL(window.location.href);

  if (locn.host === targetHost && locn.pathname === targetPathname) {
    return false;
  }

  locn.host = targetHost;
  locn.pathname = targetPathname;

  console.warn(`Executing a build_path (${build_url.href}) browser redirect to "${locn.href}"`);
  window.location.href = locn.href;

  return true;
}

/** redirect to the user's build_path as required. If build_path is a URL it should include the full pathname,
 * typically including the Login component */
export function maybeRedirectToBuildPath({ build_path }: { build_path: string }) {
  function stripCurrentStoryPathname(pathname: string) {
    const [storyPrefix] = RegExp('^/vl(nhs|axa|m|s|t|b)-[^/]*-(story|bug)').exec(pathname) || [];

    return storyPrefix ? pathname.substring(storyPrefix.length) : pathname;
  }

  try {
    const build_url = new URL(build_path);

    return maybeRedirectToBuildURL(build_url);
  } catch {
    //
  }

  build_path = build_path?.replace('/', '');

  if (!build_path) return '';

  const { pathname } = window.location;
  const currentBuildPath = pathname.split('/').find((s) => s.length > 0);

  if (currentBuildPath !== build_path) {
    // OK, I'm on the wrong build_path, time to redirect
    const url = new URL(window.location.href);

    url.pathname = `${build_path}${stripCurrentStoryPathname(url.pathname)}`;
    while (url.pathname.endsWith('//')) {
      url.pathname = url.pathname.slice(0, -1);
    }
    console.log('Redirecting to build_path (maybeRedirectToBuildPath):', url.toString());
    window.location.replace(url);

    return true;
  }

  return '';
}

/** redirect to the user's build_path as required */
export function useRedirectToBuildPath() {
  const { build_path } = useUserInBothAppAndAdmin().user || {};

  useEffect(() => {
    maybeRedirectToBuildPath({ build_path });
  }, [build_path]);
}

export default useRedirectToBuildPath;
